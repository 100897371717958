<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="font-weight-light">
              ข้าราชการครูและบุคลากรทางการศึกษา
            </div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="showAlldata">
              <template v-slot:top>
                <v-text-field
                  v-model="search_branch"
                  label="ค้นหา :"
                  class="mx-4"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DashboardDashboard",

  data() {
    return {
      ApiKey: "HRvec2021",
      search_branch: "",
      headers: [
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "จำนวนทั้งหมด", align: "left", value: "count_all" },
        { text: "จำนวนผู้อำนวยการ", align: "left", value: "count_dr" },
        { text: "จำนวนรองผู้อำนวยการ", align: "left", value: "count_se_dr" },
        { text: "จำนวนครู", align: "left", value: "count_tech" },
        { text: "จำนวนครูผู้ช่วย", align: "left", value: "count_perpare" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      user: [],
      showAlldata: []
    };
  },
  async mounted() {
    await this.getuser();
    await this.getAlldata();
  },

  methods: {
    async getuser() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: "HRvec2021"
      });
      this.user = result.data;
      
    },

    async getAlldata() {
      let result = await this.$http.post("show_dashboard_all.php", {
        collegeVei: this.user.user_code
      });
      this.showAlldata = result.data;
    }
  }
};
</script>
